import { LegacyImage, StrokeText, Text } from '$shared/components';
import { Variants } from 'framer-motion';
import React, { memo } from 'react';
import { PromotedNavigationNode } from '~/lib/data-contract';
import { Link } from '~/shared/components/Link';
import { ConditionalWrap, weakKey } from '~/shared/utils/jsx';
import { queriesIsolated } from '~/theme';
import {
    StyledPromotedContent,
    StyledPromotedImageWrapper,
    StyledPromotedItemContent,
} from './styled';

/**
 * A list of link images, with optional texts
 */
export const PromotedChildren = memo(
    ({ nodes, variants }: { nodes?: PromotedNavigationNode[]; variants?: Variants }) => (
        <StyledPromotedContent variants={variants} itemLength={nodes?.length}>
            {nodes?.map(({ image, link, text }, i) => {
                // The last image gets a smaller width
                const isLastItem = i === 1;
                const imageSize = {
                    w: isLastItem ? 280 : 420,
                    h: 365,
                };
                const sizes = `
                    ${queriesIsolated.xs} 50vw,
                    ${queriesIsolated.lg} 30vw,
                    ${isLastItem ? '15vw' : '22vw'}`;

                return (
                    <ConditionalWrap
                        key={weakKey({ image, link, text })}
                        if={Boolean(link?.url)}
                        wrap={(itemContent) => (
                            <Link
                                href={link?.url || ''}
                                passHref={true}
                                prefetch={false}
                                {...link}
                                children={itemContent}
                                type="router"
                            />
                        )}
                    >
                        <StyledPromotedItemContent>
                            {image?.src && (
                                <StyledPromotedImageWrapper {...imageSize}>
                                    <LegacyImage
                                        {...image}
                                        src={image.src}
                                        cW={imageSize.w}
                                        cH={imageSize.h}
                                        layout="fill"
                                        objectFit="cover"
                                        sizes={sizes}
                                        hoverStyle="both"
                                    />
                                </StyledPromotedImageWrapper>
                            )}
                            {text && (
                                <Text variant="display4" as="span">
                                    <StrokeText text={text} />
                                </Text>
                            )}
                        </StyledPromotedItemContent>
                    </ConditionalWrap>
                );
            })}
        </StyledPromotedContent>
    )
);
