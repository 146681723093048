import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import { getClampFullRange } from '~/theme/utils/getClampValue';

export const StyledFooter = styled.footer(({ theme }) => ({
    backgroundColor: theme.colors.light,
    borderTop: theme.borders.border,
    '&:empty': {
        display: 'none',
    },
}));

export const StyledFooterTrustpilotWrapper = styled.div(({ theme }) => ({
    padding: `${getClampFullRange(theme.spacesRaw[4], theme.spacesRaw[6])} 0`,
    borderBottom: theme.traits.line.asBorderStyle,
    display: 'grid',
    alignItems: 'center',
    minHeight: 200,
    [breakpoints.md]: {
        minHeight: 260,
    },
    [breakpoints.lg]: {
        minHeight: 300,
    },
}));

export const StyledGrid = styled.div(({ theme }) => ({
    display: 'grid',
    rowGap: theme.spaces[5],
    columnGap: theme.gridConfig.gap,
    gridTemplateAreas: `"richtext"
                        "menu"
                        "newsletter"
                        "icons"`,
    '&:empty': {
        display: 'none',
    },
    [breakpoints.md]: {
        gridAutoFlow: 'column',
        gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.md}, 1fr)`,
        gridTemplateAreas: `"richtext richtext richtext . divider menu menu menu menu newsletter newsletter newsletter"
                            "icons    icons    icons    . divider menu menu menu menu newsletter newsletter newsletter"`,
    },
}));

export const StyledDivider = styled.div(({ theme }) => ({
    display: 'none',
    gridArea: 'divider',
    width: 1,
    background: theme.colors.dark,
    marginTop: `calc(${getClampFullRange(theme.spacesRaw[6], theme.spacesRaw[8])} * -1)`,
    marginBottom: `calc(${getClampFullRange(theme.spacesRaw[4], theme.spacesRaw[8])} * -1)`,

    [breakpoints.md]: {
        display: 'block',
    },
}));

export const StyledGridWrapper = styled.div(({ theme }) => ({
    padding: `${getClampFullRange(theme.spacesRaw[6], theme.spacesRaw[8])} 0 ${getClampFullRange(
        theme.spacesRaw[4],
        theme.spacesRaw[8]
    )}`,
}));
