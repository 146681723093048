import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { getClampFullRange } from '~/theme/utils/getClampValue';

export const StyledN10Meta = styled.div(({ theme }) => ({
    position: 'relative',
    zIndex: theme.zIndices[4],
    backgroundColor: theme.colors.dark,
    color: theme.colors.dark,
    display: 'flex',
    alignItems: 'center',
    height: theme.sizes.metaHeight,

    [breakpoints.md]: {
        height: theme.sizes.metaHeightDesktop,
    },
}));

export const StyledContent = styled.div(({ theme }) => ({
    padding: `${getClampFullRange(theme.spacesRaw[2], theme.spacesRaw[1])} ${
        theme.gridConfig.gutter
    }`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: theme.fontSizes.lg,
    '&:only-child': {
        margin: '0 auto',
    },
}));

export const StyledList = styled.ul(({ theme }) => ({
    display: 'none',
    listStyle: 'none',
    gap: theme.spaces[5],
    marginLeft: 'auto',
    [breakpoints.md]: {
        display: 'flex',
    },
}));
