import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledMeta = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: theme.spaces[3],
    color: theme.colors.light,
    [breakpoints.md]: {
        // This adds fixed spacing right side
        // So icons does not collide with widgets
        paddingRight: theme.spaces[9],
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

export const StyledBackground = styled.div(({ theme }) => ({
    padding: `
    ${theme.spaces[2]}
    ${theme.gridConfig.gutter}`,
    background: theme.colors.dark,
}));

export const StyledSoMeItems = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[4],
}));

export const StyledMetaRTEWrapper = styled.div(({ theme }) => ({
    textAlign: 'left',
    margin: `${theme.spaces[1]} 0 ${theme.spaces[3]}`,

    p: {
        marginBottom: '0 !important',
    },

    'a, button': {
        fontSize: theme.fontSizesFluid.sm,
        display: 'inline-block',
        margin: `${theme.spaces[1]} 2ch ${theme.spaces[1]} 0`,
    },
}));
