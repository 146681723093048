import styled from '@emotion/styled';

export const StyledUspContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    height: '1em',

    a: {
        color: 'inherit',

        '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },
});

export const StyledWrapper = styled.div(() => ({
    position: 'relative',
}));

export const StyledAccesibilityUspDisplay = styled.ul(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    background: theme.colors.dark,
    width: 'clamp(200px, 50vw, 480px)',
    padding: theme.spaces[2],
    borderRadius: 3,
}));

export const StyledLiWrapper = styled.li(() => ({
    listStyle: 'none',
}));
