import {
    StyledSearchSuggestions,
    StyledSearchSuggestionsContainer,
    StyledSearchSuggestionsItem,
    StyledSearchSuggestionsItems,
    StyledSearchSuggestionsTitle,
} from '$shared/components/SearchSuggestions/styled';
import { useThemeShade } from '$theme';
import { useFrame, useTranslation } from '$shared/utils';
import { RouterLink } from '../RouterLink';

export type SearchSuggestionsProps = {
    initialBackgroundColor?: string;
    gutter?: boolean;
};

export const SearchSuggestions = ({
    initialBackgroundColor,
    gutter = true,
}: SearchSuggestionsProps) => {
    const { translate } = useTranslation();
    const { data: frameData } = useFrame();
    const { textShade, buttonShade } = useThemeShade({
        backgroundColor: initialBackgroundColor,
    });

    if (!frameData?.search?.popularCategories?.length) {
        return <></>;
    }

    return (
        <StyledSearchSuggestions>
            <StyledSearchSuggestionsTitle variant={'caption'} shade={textShade} gutter={gutter}>
                {translate('modules.searchModuleSuggestionCategoryTitle')}
            </StyledSearchSuggestionsTitle>
            <StyledSearchSuggestionsContainer shadowColor={initialBackgroundColor}>
                <StyledSearchSuggestionsItems gutter={gutter} shade={buttonShade}>
                    {frameData.search.popularCategories.map((link, index) => (
                        <RouterLink href={link.url ?? ''} key={index}>
                            <StyledSearchSuggestionsItem
                                as={'a'}
                                textShade={textShade}
                                shade={buttonShade}
                                href={link.url || ''}
                                target={link.target}
                                variant="secondary"
                                size="sm"
                                rounded
                                shape="icon"
                                gutter={gutter}
                            >
                                {link.title || link.text}
                            </StyledSearchSuggestionsItem>
                        </RouterLink>
                    ))}
                </StyledSearchSuggestionsItems>
            </StyledSearchSuggestionsContainer>
        </StyledSearchSuggestions>
    );
};
