import React from 'react';
import { StyledGridItem } from './styled';

export type GridItemProps = {
    area?: string;
    children?: React.ReactNode;
    style?: React.StyleHTMLAttributes<'div'>;
};

export const GridItem = StyledGridItem;
