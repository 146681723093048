import { Variants } from 'framer-motion';

export const animationOptions: Record<string, Variants> = {
    fade: {
        initial: {
            opacity: 0,
            position: 'absolute',
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
        animate: {
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 0.5,
            },
        },
    },
    none: {
        initial: {
            display: 'block',
        },
        exit: {
            display: 'none',
        },
        animate: {
            display: 'block',
        },
    },
};
