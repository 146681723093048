import { useTheme } from '@emotion/react';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { leverageNextLocaleCookieClient } from '~/services/utils/next-locale';
import { Overlay, Text } from '~/shared/components';
import { useFranchise } from '~/shared/utils/franchise';
import { useTranslation } from '~/shared/utils/translation';
import { Market } from './components/Market';
import { MarketBar } from './components/MarketBar';
import { MarketButton } from './components/MarketButton';
import { StyledMarketContent, StyledMarketList } from './styled';

const LOCATION_DETECTION_COOKIE_KEY = 'LOCATION_DETECTED';

export const MarketSelector = memo(() => {
    const { franchiseMarket, activeMarket } = useFranchise();
    const { translate } = useTranslation();
    const theme = useTheme();
    const { locale } = useRouter();
    const [selectorActive, setSelectorActive] = useState(false);
    const [barSelectorActive, setBarSelectorActive] = useState(false);
    const locationDetected = useMemo(
        () => cookie.get(LOCATION_DETECTION_COOKIE_KEY) !== 'false',
        []
    );

    const removeLocationCookie = useCallback(() => {
        cookie.remove(LOCATION_DETECTION_COOKIE_KEY);
    }, []);

    const onBarDismiss = useCallback(() => {
        setBarSelectorActive(false);
        removeLocationCookie();
    }, [removeLocationCookie]);

    const onSelectorDismiss = useCallback(() => setSelectorActive(false), [setSelectorActive]);

    useEffect(() => {
        if (!locationDetected) {
            setBarSelectorActive(true);
        }
    }, [locationDetected]);

    useEffect(() => {
        leverageNextLocaleCookieClient(locale);

        if (selectorActive && !locationDetected) {
            removeLocationCookie();
        }
    }, [locale, locationDetected, removeLocationCookie, selectorActive]);

    return (
        <>
            {barSelectorActive && (
                <MarketBar onDismiss={onBarDismiss} onSelect={() => setSelectorActive(true)}>
                    <Text style={{ color: theme.colors.light, textAlign: 'center' }}>
                        {activeMarket?.countryName &&
                            translate('market.barNotice', { country: activeMarket.countryName })}
                    </Text>
                </MarketBar>
            )}
            <MarketButton onSelect={() => setSelectorActive(true)} />

            <Overlay
                variant="minimal"
                open={selectorActive}
                onDismiss={onSelectorDismiss}
                headline={translate('market.marketSelectorHeadline')}
            >
                <StyledMarketContent>
                    <StyledMarketList>
                        {franchiseMarket?.markets?.map((market) => (
                            <li key={market.countryKey}>
                                <Market active={locale === market.locale} {...market} />
                            </li>
                        ))}
                    </StyledMarketList>
                </StyledMarketContent>
            </Overlay>
        </>
    );
});
