import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledIconArea = styled.div({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',

    [breakpoints.lg]: {
        flexDirection: 'row',
    },
});

export const StyledImageContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    rowGap: theme.spaces[2],
    columnGap: theme.spaces[3],
    img: {
        maxWidth: 35,
        [breakpoints.sm]: {
            maxWidth: 45,
        },
    },
}));

export const StyledImageWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[4],
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [breakpoints.md]: {
        flexDirection: 'row',
    },
}));
