import React, { useRef, useEffect, useCallback } from 'react';
import { debounce, useTranslation } from '~/shared/utils';
import { StyledInput, StyledSearchContainer, StyledSearchIcon } from './styled';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import Loupe from '$icons/search.svg';
import Clear from '$icons/close.svg';
import { useSearchHook, useSearchRouting } from '~/features/navigation';

export const N25SearchBar = () => {
    const { clearSearch } = useSearchHook();

    const { searchPageRouteConnector, searchQuery } = useSearchRouting();
    const { translate } = useTranslation();
    const ref = useRef<HTMLInputElement | null>(null);

    const onSearchClear = useCallback(() => {
        if (ref?.current) {
            ref.current.value = '';
        }
        clearSearch();
        searchPageRouteConnector('');
    }, [clearSearch, searchPageRouteConnector]);

    useEffect(() => {
        const searchInputRef = ref?.current;

        if (document.activeElement?.id === searchInputRef?.id) {
            return;
        }

        if (searchInputRef && searchInputRef.value !== searchQuery) {
            searchInputRef.value = searchQuery;
        }
    }, [searchQuery]);

    const doSearch = (searchTerm: string) => {
        searchPageRouteConnector(searchTerm);
    };

    const onInputHandler = debounce(doSearch, 500);

    return (
        <StyledSearchContainer>
            <StyledInput
                type="search"
                id="headerSearchField"
                ref={ref}
                defaultValue={searchQuery}
                placeholder={translate('header.search.placeholder')}
                aria-label={translate('header.search.placeholder')}
                onInput={(event) => onInputHandler(event.currentTarget.value)}
            />
            <StyledSearchIcon shape="icon">
                {!searchQuery ? (
                    <Loupe aria-hidden="true" />
                ) : (
                    <Clear aria-hidden="true" onClick={onSearchClear} />
                )}
                <VisuallyHidden
                    children={
                        ref?.current?.value.length
                            ? translate('accessibility.search')
                            : translate('accessibility.clear')
                    }
                />
            </StyledSearchIcon>
        </StyledSearchContainer>
    );
};
