import React, { PropsWithChildren } from 'react';
import { StyledCloseButton, StyledMarketBarContent, StyledMarketBarWrapper } from '../styled';
import { Button } from '~/shared/components';
import { MarketButton } from './MarketButton';
import Close from '$icons/close.svg';
import { useTranslation } from '~/shared/utils/translation';
import { Spacer } from '~/theme';
type Props = {
    onDismiss: () => void;
    onSelect: () => void;
};

export const MarketBar = ({ onDismiss, onSelect, children }: PropsWithChildren<Props>) => {
    const { translate } = useTranslation();

    return (
        <StyledMarketBarWrapper>
            {children}
            <Spacer space="3" />
            <StyledMarketBarContent>
                <MarketButton onSelect={onSelect} shade="dark" />
                <Button onClick={onDismiss} variant="secondary" size="sm">
                    {translate('general.continue')}
                </Button>
                <StyledCloseButton onClick={onDismiss}>
                    <Close title={translate('general.close')} />
                </StyledCloseButton>
            </StyledMarketBarContent>
        </StyledMarketBarWrapper>
    );
};
