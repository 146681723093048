import React, { memo } from 'react';
import { Link as LinkType } from '~/lib/data-contract';
import { Link } from '~/shared/components';

export type LinkVariant = 'header' | 'link';

type Props = {
    link?: LinkType;
    /**
     * - 'header'       Column header links
     * - 'link'         List item link
     */
    linkVariant?: LinkVariant;
};

/**
 * Navigation link with variants.
 *
 */
export const NavigationLink = memo(({ link, linkVariant = 'link' }: Props) => {
    if (!link?.url) {
        return <></>;
    }

    return (
        <Link
            href={link.url}
            prefetch={false}
            target={link.target}
            title={link.title}
            strokeHeight={linkVariant === 'header' ? '2px' : '1px'}
            text={link.text || link.title}
            textVariant={linkVariant === 'header' ? 'display4' : 'bodySm'}
        />
    );
});
