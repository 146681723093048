import React from 'react';
import { Link as LinkType } from '~/lib/data-contract';
import { Button, RawHtml, RouterLink } from '~/shared/components';
import { Spacer } from '~/theme';
import { GridItem, GridItemProps } from '../GridItem';
import { StyledNewsLetter } from './styled';

type NewsletterAreaProps = GridItemProps & {
    link?: LinkType;
    text?: string;
};

export const NewsletterArea = ({ text, link }: NewsletterAreaProps) => {
    return (
        <GridItem area="newsletter">
            <StyledNewsLetter>
                {text && <RawHtml html={text} />}
                <Spacer space="4" />
                {link?.url && (
                    <RouterLink href={link.url}>
                        <Button
                            as="a"
                            variant="secondary"
                            target={link.target}
                            title={link.title}
                            children={link.text}
                        />
                    </RouterLink>
                )}
            </StyledNewsLetter>
        </GridItem>
    );
};
