import { Variants } from 'framer-motion';
import { ThemeType } from '~/theme';
import { Duration, EasingModes, EasingTypes } from '~/theme/themes/animations/baseAnimations';

const durationKey: Duration = 'moderate02';
const extendedDurationKey: Duration = 'fast02';
// Used to finetune duration, without
// big jumps between defined Durations.
const durationMultiplier = 1;
// Using standard easingType for everything here, because
// the menu still "lives", it is just faded and out of view
const easingType: EasingTypes = 'standard';
const easingMode: EasingModes = 'expressive';

const nonAnimatedVariants: Variants = {
    active: {
        display: '',
        x: 0,
        opacity: 1,
    },
    switchIn: {
        display: '',
        x: 0,
        opacity: 1,
        transition: {
            duration: 0,
        },
    },
    switchOut: {
        display: '',
        transition: {
            duration: 0,
        },
        transitionEnd: {
            display: 'none',
        },
    },
    inactive: {
        x: 0,
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
    },
};

/**
 * @description
 * The main dropDown container.
 * Slides and fades in from left.
 * If switching between 2 dropdowns, the slide and fade does not happen.
 * Sets 'display: "none"' when done animating. This makes sure we do not have tabable items, but ensures link crawlability
 */

const animatedDropDownVariants = ({
    animations: { durations, bezierValues },
}: ThemeType): Variants => {
    const duration = durations[durationKey] * durationMultiplier;
    const durationHalved = duration / 2;
    const ease = bezierValues[easingType][easingMode];

    return {
        active: {
            display: 'block',
            opacity: 1,
            x: 0,
            transition: {
                duration,
                ease,
            },
        },
        switchIn: {
            display: 'block',
            opacity: 1,
            x: 0,
            transition: {
                ease,
                duration: 0,
            },
        },
        switchOut: {
            display: 'block',
            transition: {
                ease,
            },
            transitionEnd: {
                x: '-100%',
                display: 'none',
            },
        },
        inactive: {
            x: '-100%',
            opacity: 0,
            transition: {
                duration,
                ease,
                opacity: {
                    delay: durationHalved,
                    duration: durationHalved,
                },
            },
            transitionEnd: {
                display: 'none',
            },
        },
    };
};

/**
 * @description Draws the line beneath the menu
 */
const animatedLineVariants = ({ animations: { durations, bezierValues } }: ThemeType): Variants => {
    const duration = (durations[durationKey] + durations[extendedDurationKey]) * durationMultiplier;
    const ease = bezierValues[easingType][easingMode];
    const scaleX = 0.5;

    return {
        active: {
            scaleX: 1,
            transition: {
                duration,
                ease,
            },
        },
        switchIn: {
            scaleX: 1,
            transition: {
                duration: 0,
            },
        },
        switchOut: {
            scaleX: 1,
            transitionEnd: {
                scaleX,
            },
        },
        inactive: {
            scaleX,
            transition: {
                duration,
                ease,
            },
        },
    };
};

/**
 * @description
 * Counteracts the effect of the dropDown x transition.
 * Can be tweaked to make the content follow the dropDown more/less
 */
const animatedDropDownContentVariants = ({
    animations: { durations, bezierValues },
}: ThemeType): Variants => {
    const duration = durations[durationKey] * durationMultiplier;
    const ease = bezierValues[easingType][easingMode];
    const x = '98%';

    return {
        active: {
            x: 0,
            transition: {
                duration,
                ease,
            },
        },
        switchIn: {
            x: [0, 0], // Don't do any translate at all
            transition: {
                ease,
                duration,
            },
        },
        switchOut: {
            transition: {
                duration: 0,
                ease,
            },
            transitionEnd: {
                x,
            },
        },
        inactive: {
            x,
            transition: {
                duration,
                ease,
            },
        },
    };
};

/**
 * @description
 * Makes the sections of the menu fade in from left, with a possibility of
 * a delay for a stagger effect (staggerChildren did not seem to work on parent)
 */
const animatedMenuSectionVariants = (
    { animations: { durations, bezierValues } }: ThemeType,
    delayKey?: Duration
): Variants => {
    const duration = (durations[durationKey] + durations[extendedDurationKey]) * durationMultiplier;
    const activeDelay = durations[extendedDurationKey];
    const delay = delayKey ? durations[delayKey] : 0;
    const durationHalved = duration / 2;
    const ease = bezierValues[easingType][easingMode];
    const x = '-2%';
    return {
        active: {
            opacity: 1,
            x: 0,
            transition: {
                duration: duration + delay, // Using + delay here for better effect when active
                delay: activeDelay,
                ease,
            },
        },
        switchIn: {
            opacity: 1,
            x: 0,
            transition: {
                ease,
                duration,
                delay,
            },
        },
        switchOut: {
            opacity: 0,
            x: 0,
            transition: {
                duration,
                delay: 0,
                ease,
            },
            transitionEnd: {
                x,
            },
        },
        inactive: {
            opacity: 0,
            x,
            transition: {
                duration: durationHalved,
                delay: 0,
                ease,
            },
        },
    };
};

export const dropDownVariants = (
    prefersReducedMotion: boolean | null,
    theme: ThemeType
): Variants => (prefersReducedMotion ? nonAnimatedVariants : animatedDropDownVariants(theme));

export const lineVariants = (prefersReducedMotion: boolean | null, theme: ThemeType): Variants =>
    prefersReducedMotion ? nonAnimatedVariants : animatedLineVariants(theme);

export const dropDownContentVariants = (
    prefersReducedMotion: boolean | null,
    theme: ThemeType
): Variants =>
    prefersReducedMotion ? nonAnimatedVariants : animatedDropDownContentVariants(theme);

export const menuSectionVariants = (
    prefersReducedMotion: boolean | null,
    theme: ThemeType,
    delayKey?: Duration
): Variants =>
    prefersReducedMotion ? nonAnimatedVariants : animatedMenuSectionVariants(theme, delayKey);
