import styled from '@emotion/styled';
import { breakpoints, pxToRem } from '~/theme';
import { StyledButton } from '$shared/components';
import { searchReset } from '~/shared/utils/styled/searchReset';

export const StyledSearchContainer = styled.div<{ hide?: boolean }>(({ theme, hide }) => ({
    display: hide ? 'none' : 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    height: theme.sizes.N20headerHeight,
    width: '100%',
    left: 0,
    borderTop: theme.borders.border,
    borderBottom: theme.borders.border,
    justifyContent: 'space-between',
    overflow: 'hidden',
    '&:after': {
        content: '""',
        position: 'absolute',
        inset: 'auto 0 0 0',
        height: 3,
        transform: 'translateY(100%)',
        backgroundColor: 'currentcolor',
        transitionDuration: theme.animations.getDuration('moderate01'),
        transitionProperty: 'transform',
        transitionTimingFunction: theme.animations.getEasing('standard', 'expressive'),
    },
    '&:focus-within': {
        '&:after': {
            transform: 'translateY(0)',
        },
    },

    [breakpoints.md]: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        top: 0,
        maxWidth: 340,
        border: 'none',
        borderLeft: theme.borders.border,
        borderRight: theme.borders.border,
        backgroundColor: theme.colors.light30,
    },
}));

export const StyledInput = styled.input(
    ({ theme: { spaces, fontSizesFluid, fontSizes, gridConfig } }) => ({
        backgroundColor: 'initial',
        border: 'none',
        width: '100%',
        height: '100%',
        minWidth: spaces[7],
        fontSize: fontSizes.md,
        padding: `${spaces[2]} ${spaces[6]} ${spaces[2]} ${gridConfig.gutter}`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...searchReset,
        [breakpoints.md]: {
            padding: `${spaces[2]} ${spaces[7]} ${spaces[2]} ${spaces[5]}`,
            fontSize: fontSizesFluid.md,
        },
    })
);

export const StyledSearchIcon = styled(StyledButton)(({ theme }) => ({
    position: 'absolute',
    inset: `0 ${theme.gridConfig.gutter} 0 auto`,
    transform: `translateX(${pxToRem(12)})`, // To offset padding
    outlineOffset: '-1px',

    [breakpoints.md]: {
        right: theme.spaces[3],
        transform: 'none',
    },
}));
