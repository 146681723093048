import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledNewSearchPageWrapper = styled.div(({ theme }) => ({
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.dark,
}));

export const StyledNewSearchPageBackDrop = styled.div<{ searchQuery?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.dark,
        color: theme.text.color.paragraphInverted,
        transitionDuration: theme.animations.getDuration('moderate01'),
        transitionProperty: 'all',
        transitionTimingFunction: theme.animations.getEasing('standard', 'expressive'),
        transform: 'translateY(64px)',
    }),
    ifProp('searchQuery', ({ theme }) => ({
        transitionDuration: theme.animations.getDuration('moderate01'),
        transitionProperty: 'all',
        transitionTimingFunction: theme.animations.getEasing('standard', 'expressive'),
        transform: 'translateY(30px)',
    }))
);

export const StyledNewSearchPageBackDropContent = styled.div({
    display: 'flex',
    flexDirection: 'column',
});

export const StyledNewSearchPageData = styled.div<{
    searchQuery?: boolean;
    filterDrawerIsOpen?: boolean;
}>(
    ({ theme }) => ({
        display: 'none',
        backgroundColor: theme.colors.light,
        transitionDuration: theme.animations.getDuration('moderate01'),
        transitionProperty: 'all',
        transitionTimingFunction: theme.animations.getEasing('standard', 'expressive'),
        opacity: 0,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        marginTop: '30px',
    }),
    ifProp('searchQuery', ({ theme }) => ({
        display: 'block',
        transitionDuration: theme.animations.getDuration('moderate01'),
        transitionProperty: 'all',
        transitionTimingFunction: theme.animations.getEasing('standard', 'expressive'),
        opacity: 1,
    })),
    ifProp('filterDrawerIsOpen', () => ({
        display: 'contents',
    }))
);
