import React from 'react';
import { MarketItemViewModel } from '~/lib/data-contract';
import Check from '$icons/check.svg';
import { StyledMarketLink, StyledMarketText } from '../styled';

type Props = MarketItemViewModel & {
    active?: boolean;
};

export const Market = (props: Props) => {
    const { countryName, languageName, currency, active, locale } = props;

    return (
        <StyledMarketLink href={`/${locale}`} active={active} type="raw">
            <span>
                <StyledMarketText variant="body">
                    {countryName} {currency ? ` - ${currency}` : undefined}
                </StyledMarketText>
                {languageName}
            </span>
            {active && <Check />}
        </StyledMarketLink>
    );
};
