import { useFrame } from '$shared/utils';
import React, { memo } from 'react';
import { Gutter } from '~/shared/components';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { N80Trustpilot } from '../N80Trustpilot';
import { IconArea } from './components/IconArea';
import { MenuArea } from './components/MenuArea';
import { MetaArea } from './components/MetaArea';
import { NewsletterArea } from './components/NewsletterArea';
import { RichTextArea } from './components/RichTextArea';
import {
    StyledDivider,
    StyledFooter,
    StyledGrid,
    StyledGridWrapper,
    StyledFooterTrustpilotWrapper,
} from './styled';

export const N60Footer = memo(() => {
    const { data: frame } = useFrame();

    const {
        contactText,
        menu,
        newsletterText,
        newsletterLink,
        additionalIcons,
        footerMeta,
        copyrights,
    } = frame?.footer || {};

    const showGrid = menu || contactText || newsletterText || newsletterLink;

    return (
        <StyledFooter>
            {frame?.footer?.trustpilotWidgetSnippet && (
                <StyledFooterTrustpilotWrapper>
                    <N80Trustpilot markup={frame?.footer?.trustpilotWidgetSnippet} />
                </StyledFooterTrustpilotWrapper>
            )}
            {showGrid && (
                <Gutter>
                    <StyledGridWrapper>
                        <StyledGrid>
                            <RichTextArea text={contactText} />
                            <StyledDivider aria-hidden="true" />
                            {menu && <MenuArea menu={menu} />}
                            {newsletterText || newsletterLink ? (
                                <NewsletterArea text={newsletterText} link={newsletterLink} />
                            ) : null}
                            <IconArea
                                paymentIcons={frame?.ecommerce?.paymentIcons}
                                additionalIcons={additionalIcons}
                            />
                        </StyledGrid>
                    </StyledGridWrapper>
                </Gutter>
            )}
            <MetaArea
                copyRightNotice={copyrights}
                footerMeta={footerMeta}
                soMeLinks={frame?.footer?.socialLinks}
            />
        </StyledFooter>
    );
});

export default withErrorBoundary(N60Footer);
