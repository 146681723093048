import { StyledAccordionItem } from '$shared/components';
import { breakpoints } from '$theme';
import styled from '@emotion/styled';

export const StyledMobileMenu = styled.div({
    display: 'grid',
    [breakpoints.md]: {
        display: 'none',
    },
});

export const StyledDesktopMenu = styled.div(({ theme }) => ({
    display: 'none',
    gap: theme.spaces['4'],
    [breakpoints.md]: {
        display: 'grid',
    },
}));

export const StyledMenuContainer = styled.div(({ theme }) => ({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '1fr',
    gridTemplateRow: 'min-content',
    gridAutoRows: 'max-content',
    margin: `0 calc(${theme.gridConfig.gutter} * -1)`,
    [breakpoints.md]: {
        margin: 0,
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        rowGap: theme.spaces[6],
        columnGap: theme.gridConfig.gap,
    },
}));

export const StyledMenuGroup = styled.div({
    display: 'block',
    gridAutoFlow: 'row',
    [breakpoints.md]: {
        display: 'grid',
    },

    [`& + & ${StyledAccordionItem}`]: {
        borderTop: 'none',
    },
});

export const StyledMenuItems = styled.ul(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces['3'],
}));
