import { useFrame } from '$shared/utils';
import React, { useEffect } from 'react';
import { N35MobileMegaMenu } from '~/features/navigation';
import { N30MegaMenu } from '~/features/navigation/components/N30MegaMenu';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import LeftArrow from '$icons/arrow-left.svg';
import { useTranslation } from '~/shared/utils/translation';
import { useMobileMenu } from '../N35MobileMegaMenu/hooks/useMobileMenu';
import { MiniBasketButton } from './components/MiniBasketButton';
import { MobileMegaMenuButton } from './components/MobileMegaMenuButton';
import { N25SearchBar } from '../N25SearchBar/N25SearchBar';
import {
    StyledContent,
    StyledDesktopNav,
    StyledIcons,
    StyledLogoContainer,
    StyledMainNavigation,
    StyledMobileMenuHeader,
    StyledLeftArrow,
    StyledMobileMenuContainer,
    StyledMobileSearchBar,
    StyledDesktopSearchBar,
    StyledLogoWrapperDesktop,
    StyledLogoWrapperMobile,
} from './styled';
import { useState } from 'react';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import { Logo } from '~/shared/components/Logo';
import { N70NavigationIndicator } from '../N70NavigationIndicator';
import Search from '$icons/solidAsh/search.svg';
import {
    StyledBackArrow,
    StyledMetaButton,
    StyledSearchText,
} from '$features/navigation/components/N21MainMenuNew/styled';
import { useRouter } from 'next/router';
import { usePage } from '$templates/pages';
import { useSearchBarUIState } from '../../hooks/search/useSearchBarUIState';

const N20MainMenu = () => {
    const { data } = useFrame();
    const { mainMenu = [] } = data?.navigation || {};
    const { isOpen, activeMenuState } = useMobileMenu();
    const activeMenuHeader = activeMenuState?.node?.link?.text;
    const { isSearchEmphasized } = useSearchBarUIState();
    const { translate } = useTranslation();
    const [activeNodeId, setActiveNodeId] = useState<string | null>();
    const [pathBeforeSearchPageState, setPathBeforeSearchPageState] = useState<
        string | undefined
    >();
    const router = useRouter();
    const { type: pageType } = usePage();
    const isSearchPage = pageType === ('m170searchPage' as unknown);

    useEffect(() => {
        setActiveNodeId(null);
    }, [activeMenuState]);

    const onGoBack = () => {
        setActiveNodeId(activeMenuState.parentNode?.id ?? '');
    };

    const setPathBeforeSearchPage = () => {
        setPathBeforeSearchPageState(router.asPath);
        router.push(data?.staticLinks?.searchPage?.url || '');
    };

    const redirectBackToSavePath = () => {
        router.push(pathBeforeSearchPageState || '');
    };

    const BackArrow = ({ onClick }: { onClick: () => void }) => {
        return (
            <StyledLeftArrow onClick={onClick}>
                <LeftArrow aria-hidden="true" title="Go back" />
                <VisuallyHidden children={translate('accessibility.goBack')} />
            </StyledLeftArrow>
        );
    };

    const showActiveMenu = isOpen && activeMenuHeader;

    return (
        <StyledMainNavigation>
            <StyledContent>
                <StyledDesktopNav>
                    <N30MegaMenu navigation={mainMenu} />
                </StyledDesktopNav>
                <StyledLogoWrapperDesktop md>
                    <StyledLogoContainer>
                        <Logo />
                    </StyledLogoContainer>
                </StyledLogoWrapperDesktop>
                <StyledLogoWrapperMobile md>
                    <StyledLogoContainer>
                        {!isSearchPage && (
                            <>
                                {showActiveMenu && <BackArrow onClick={onGoBack} />}
                                {!showActiveMenu && <Logo />}
                            </>
                        )}

                        {isSearchPage && (
                            <>
                                {showActiveMenu && <BackArrow onClick={onGoBack} />}
                                {!showActiveMenu && (
                                    <StyledBackArrow onClick={redirectBackToSavePath}>
                                        <LeftArrow aria-hidden="true" title="Go back" />
                                        {translate('navigation.back')}
                                    </StyledBackArrow>
                                )}
                            </>
                        )}
                    </StyledLogoContainer>
                </StyledLogoWrapperMobile>
                {isOpen && activeMenuHeader && (
                    <StyledMobileMenuHeader children={activeMenuHeader} />
                )}
                <StyledIcons>
                    {!isOpen && !isSearchPage && !isSearchEmphasized && (
                        <StyledMobileSearchBar>
                            <StyledMetaButton
                                as="a"
                                onClick={setPathBeforeSearchPage}
                                style={{ flex: 1 }}
                            >
                                <Search />
                                <StyledSearchText variant={'bodySm'} isSmall>
                                    {translate('header.search.placeholder.mobile')}
                                </StyledSearchText>
                            </StyledMetaButton>
                        </StyledMobileSearchBar>
                    )}
                    <StyledDesktopSearchBar>
                        <N25SearchBar />
                    </StyledDesktopSearchBar>
                    <MiniBasketButton />
                    <MobileMegaMenuButton />
                </StyledIcons>
                <StyledMobileMenuContainer>
                    <N35MobileMegaMenu activeNodeByUrl={false} activeNodeId={activeNodeId} />
                </StyledMobileMenuContainer>
            </StyledContent>
            <N70NavigationIndicator />
        </StyledMainNavigation>
    );
};

export default withErrorBoundary(N20MainMenu);
