import { AccordionItem, Link, Text } from '$shared/components';
import React, { memo } from 'react';
import { weakKey } from '~/shared/utils/jsx';
import { MenuItemProps } from '..';
import { StyledDesktopMenu, StyledMenuGroup, StyledMenuItems, StyledMobileMenu } from '../styled';

export const MenuItem = memo(({ menuItem, identifier }: MenuItemProps) => {
    const { link, children } = menuItem;
    const validChildren = children ? children.filter((node) => Boolean(node.link)) : [];

    const header = <Text variant="display4" as="h4" role="heading" children={link?.text} />;

    return (
        <StyledMenuGroup>
            <StyledMobileMenu>
                <AccordionItem id={identifier || weakKey(menuItem)} header={header}>
                    <StyledMenuItems>
                        {validChildren.map(
                            ({ link }) =>
                                link?.url && (
                                    <li key={weakKey(link)}>
                                        <Link
                                            href={link.url}
                                            textVariant="bodySm"
                                            prefetch={false}
                                            target={link.target}
                                            title={link.title}
                                            text={link.text || link.title}
                                        />
                                    </li>
                                )
                        )}
                    </StyledMenuItems>
                </AccordionItem>
            </StyledMobileMenu>
            <StyledDesktopMenu>
                {header}
                <StyledMenuItems>
                    {validChildren.map(
                        ({ link }) =>
                            link?.url && (
                                <li key={weakKey(link)}>
                                    <Link
                                        href={link.url}
                                        textVariant="bodySm"
                                        prefetch={false}
                                        target={link.target}
                                        title={link.title}
                                        text={link.text || link.title}
                                    />
                                </li>
                            )
                    )}
                </StyledMenuItems>
            </StyledDesktopMenu>
        </StyledMenuGroup>
    );
});
