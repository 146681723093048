import React, { memo } from 'react';
import { useFrame } from '~/shared/utils';

import { StyledContent, StyledList, StyledN10Meta } from './styled';
import { Usp } from './components/Usp';
import { weakKey } from '~/shared/utils/jsx';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { Link } from '~/shared/components';

const N10Meta = memo(() => {
    const { data } = useFrame();
    const { metaMenu } = data?.navigation || {};
    const { uspList } = data?.usp || {};

    return (
        <StyledN10Meta>
            <StyledContent>
                {uspList?.length ? <Usp uspItems={uspList} /> : null}

                {!!metaMenu?.length && (
                    <StyledList>
                        {metaMenu?.map(
                            (link) =>
                                link.url && (
                                    <li key={weakKey(link)}>
                                        <Link
                                            textShade="paragraphInverted"
                                            href={link.url}
                                            text={link.text}
                                            {...link}
                                        />
                                    </li>
                                )
                        )}
                    </StyledList>
                )}
            </StyledContent>
        </StyledN10Meta>
    );
});

export default withErrorBoundary(N10Meta);
