import React, { memo } from 'react';
import {
    StyledMenu,
    StyledList,
    StyledMenuWrapper,
    StyledListItem,
    StyledMenuCategory,
} from './styled';
import { NavigationLink } from '../NavigationLink';

import { Link, NavigationNode } from '~/lib/data-contract';
import { weakKey } from '~/shared/utils/jsx';
import { Variants } from 'framer-motion';

export const Menu = memo(
    ({ nodes, variants }: { nodes: NavigationNode[]; link?: Link; variants?: Variants }) => (
        <StyledMenuWrapper variants={variants}>
            <StyledMenu>
                {nodes?.map((subNode) => (
                    <StyledMenuCategory key={weakKey(subNode)}>
                        <NavigationLink link={subNode.link} linkVariant="header" />
                        <StyledList quantity={subNode.children?.length || 0}>
                            {subNode.children?.map(({ link }, index) => (
                                <StyledListItem key={index}>
                                    <NavigationLink link={link} linkVariant="link" />
                                </StyledListItem>
                            ))}
                        </StyledList>
                    </StyledMenuCategory>
                ))}
            </StyledMenu>
        </StyledMenuWrapper>
    )
);
