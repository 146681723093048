import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { cover } from '~/shared/utils/styled';
import { breaks } from '~/theme';
import { getClampFullRange, getClampValue } from '~/theme/utils/getClampValue';

export const StyledList = styled.ul(({ theme }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    height: '100%',
    gap: getClampValue(breaks.md, breaks.lg, breaks.xl, 0, 8),
    paddingRight: theme.spaces[2],
    marginLeft: `calc(${theme.spaces[3]} * -1)`,
}));

export const StyledDropdown = styled(m.div)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: `calc(100% + ${theme.sizes.borderWidth}px)`,
    backgroundColor: theme.colors.light,
    overflowX: 'hidden',
}));

export const StyledDropDownContent = styled(m.div)(({ theme: { spacesRaw, gridConfig } }) => ({
    display: 'flex',
    gap: gridConfig.gap,
    padding: `${getClampFullRange(spacesRaw[5], spacesRaw[7])} ${gridConfig.gutter}`,
}));

export const StyledDropDownLine = styled(m.span)(({ theme }) => ({
    position: 'absolute',
    inset: 'auto 0 0',
    height: theme.sizes.borderWidth,
    background: theme.colors.dark,
    transformOrigin: 'left',
}));

export const StyledDropDownBackdrop = styled(m.div)(({ theme }) => ({
    ...cover,
    position: 'fixed',
    background: theme.colors.transparentDark,
    cursor: 'pointer',
    zIndex: theme.zIndices['header'] - 1,
}));
