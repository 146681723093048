import React from 'react';
import { StyledCrossLine, StyledLineContainer, StyledMobileMenuButton } from '../styled';

import { useTranslation } from '~/shared/utils/translation';
import { useMobileMenu } from '../../N35MobileMegaMenu/hooks/useMobileMenu';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';

export const MobileMegaMenuButton = () => {
    const { toggle, isOpen } = useMobileMenu();
    const { translate } = useTranslation();

    return (
        <StyledMobileMenuButton onClick={toggle}>
            <StyledLineContainer>
                <StyledCrossLine thickness={2} cross={isOpen} />
                {!isOpen && <StyledCrossLine thickness={2} cross={isOpen} />}
                <StyledCrossLine thickness={2} cross={isOpen} />
            </StyledLineContainer>
            <VisuallyHidden children={translate('navigation.mobile.mainMenu.name')} />
        </StyledMobileMenuButton>
    );
};
