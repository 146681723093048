import React from 'react';
import { StyledMarketButton } from '../styled';
import Check from '$icons/check.svg';
import { useFranchise } from '~/shared/utils';

type Props = {
    onSelect: () => void;
    shade?: 'light' | 'dark';
};

export const MarketButton = ({ onSelect, shade }: Props) => {
    const { activeMarket } = useFranchise();

    return (
        <StyledMarketButton onClick={onSelect} variant="secondary" size="sm" shade={shade}>
            {activeMarket?.countryName}
            <Check />
        </StyledMarketButton>
    );
};
