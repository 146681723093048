import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { getClampFullRange } from '~/theme/utils/getClampValue';

export const StyledMenuWrapper = styled(m.div)({
    flex: '1 1 auto',
});

export const StyledMenu = styled.div(({ theme: { spaces, spacesRaw } }) => ({
    display: 'flex',
    width: '100%',
    gap: getClampFullRange(spacesRaw[3], spacesRaw[5]),
    paddingRight: spaces[4],
}));

export const StyledList = styled.ul<{ quantity: number }>(({ quantity }) => ({
    columns: quantity > 10 ? 2 : 1,
}));

export const StyledListItem = styled.li(({ theme: { spaces, spacesRaw } }) => ({
    marginRight: getClampFullRange(spacesRaw[3], spacesRaw[6]),
    marginBottom: spaces[2],
}));

export const StyledMenuCategory = styled.div(({ theme: { spaces } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spaces[3],
}));
