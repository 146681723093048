import { css, Global, useTheme } from '@emotion/react';
import React, { PropsWithChildren, memo } from 'react';
import { CheckoutFooter, CheckoutHeader } from '~/features/checkout';

import N10Meta from '~/features/navigation/components/N10Meta';
import N20MainMenu from '~/features/navigation/components/N20MainMenu';
import N60Footer from '~/features/navigation/components/N60Footer';

import { usePage } from '~/templates/pages/hooks/usePage';
import { StyledCheckoutWrapper, StyledMain } from '../../styled';
import { useMedia } from 'react-use';
import { queries } from '$theme';
import NewSearchPage from '$templates/pages/components/NewSearchPage';

const FramedChalkGlobalStyles = () => {
    const { colors, fonts, focus, highlight } = useTheme();

    return (
        <Global
            styles={css`
                html {
                    background-color: ${colors.light};
                }

                body,
                input {
                    font-family: ${fonts.body};
                }

                *::selection {
                    background: ${highlight.background};
                    color: ${highlight.color};
                }

                *:focus-visible {
                    outline-offset: ${focus.outlineOffset};
                    outline-style: ${focus.outlineStyle};
                    outline-color: ${focus.outlineColor};
                    outline-width: ${focus.outlineWidth};
                }
            `}
        />
    );
};

const FramedChalkLayout = memo(
    ({ children }: PropsWithChildren<Record<never, never>>): JSX.Element => {
        const { type } = usePage();
        const isCheckout = type === 'p100CheckoutPage';
        const isSearchPage = type === ('m170searchPage' as unknown);
        const isMobile = !useMedia(queries.md, false);
        const SearchComponentWrapper = isSearchPage && isMobile ? NewSearchPage : React.Fragment;

        if (isCheckout) {
            return (
                <>
                    <FramedChalkGlobalStyles />
                    <StyledCheckoutWrapper>
                        <CheckoutHeader />
                        <StyledMain>{children}</StyledMain>
                        <CheckoutFooter />
                    </StyledCheckoutWrapper>
                </>
            );
        }

        return (
            <>
                <FramedChalkGlobalStyles />
                <N10Meta />
                <N20MainMenu />
                <SearchComponentWrapper>
                    <StyledMain id="content">{children}</StyledMain>
                    <N60Footer />
                </SearchComponentWrapper>
            </>
        );
    }
);

export default FramedChalkLayout;
