import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { breakpoints } from '~/theme/breakpoints';

export const StyledNavigationItem = styled.a<{ active?: boolean }>(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    margin: 'auto',
    height: '100%',
    whiteSpace: 'nowrap',
    outlineOffset: -1,
    textDecoration: 'none',
    background: 'none',

    [breakpoints.md]: {
        padding: theme.spaces[3],
    },
    '&:hover': {
        color: 'currentcolor',
        backgroundColor: theme.colors.light30,
        textDecoration: 'none',
    },
    svg: {
        height: 'calc(24em/16)',
        width: 'calc(24em/16)',
        margin: '-0.5em -0.25em',
        transitionProperty: 'transform',
        transitionDuration: theme.animations.getDuration('moderate01'),
        transitionTimingFunction: theme.animations.getEasing('standard', 'productive'),
    },
    '&[aria-expanded="true"]': {
        svg: {
            transform: 'rotate(-180deg)',
        },
    },
}));

export const StyledLineContainer = styled.div(({ theme: { spaces, sizes } }) => ({
    position: 'relative',
    overflow: 'hidden',
    height: spaces[1],
    bottom: `calc(${spaces[1]} - ${sizes.borderWidth}px)`,
}));

export const StyledLineAnimation = styled(m.div)<{ active?: boolean }>({
    position: 'absolute',
    width: '100%',
});
