import React, { ReactNode } from 'react';
import {
    StyledNewSearchPageBackDrop,
    StyledNewSearchPageWrapper,
    StyledNewSearchPageBackDropContent,
    StyledNewSearchPageData,
} from './styled';
import { SearchSuggestions } from '$shared/components';
import { useSearchRouting, N26SearchBarNew } from '$features/navigation';
import { useTheme } from '@emotion/react';

export type NewSearchPageProps = {
    children?: ReactNode;
};

export const NewSearchPage = ({ children }: NewSearchPageProps) => {
    const { searchQuery } = useSearchRouting();
    const theme = useTheme();
    return (
        <StyledNewSearchPageWrapper>
            <StyledNewSearchPageBackDrop searchQuery={!!searchQuery}>
                <StyledNewSearchPageBackDropContent>
                    <N26SearchBarNew isFullSearchPage />
                    {!searchQuery && (
                        <SearchSuggestions
                            initialBackgroundColor={theme.colors.dark}
                            gutter={true}
                        />
                    )}
                </StyledNewSearchPageBackDropContent>
            </StyledNewSearchPageBackDrop>
            <StyledNewSearchPageData searchQuery={!!searchQuery}>
                {children}
            </StyledNewSearchPageData>
        </StyledNewSearchPageWrapper>
    );
};
