import React, { memo, useState } from 'react';
import { UspSimple } from '~/lib/data-contract';
import {
    StyledAccesibilityUspDisplay,
    StyledLiWrapper,
    StyledUspContainer,
    StyledWrapper,
} from './styled';
import { animationOptions } from './animations';
import { Usp as UspItem } from '~/shared/components';
import { useInterval } from 'react-use';
import { m, Variants } from 'framer-motion';
import { weakKey } from '~/shared/utils/jsx';

export type Props = {
    uspItems?: UspSimple[];
    animationDelay?: number;
    animationType?: Variants;
};

/**
 * Takes a list of USP's and
 * loops through them. Animation
 * properties can be controlled.
 */

export const Usp = memo(
    ({ uspItems = [], animationDelay = 5000, animationType = animationOptions.fade }: Props) => {
        const [shownItem, setShownItem] = useState(0);
        const [paused, setPaused] = useState(false);
        const [isTabFocus, setIsTabFocus] = useState(false);

        useInterval(
            () => {
                setShownItem((prev) => ++prev % uspItems.length);
            },
            paused ? null : animationDelay
        );

        return (
            <StyledUspContainer
                onMouseOver={() => setPaused(true)}
                onMouseOut={() => setPaused(false)}
                onKeyDown={(event) => {
                    if (event.code === 'Tab') {
                        setIsTabFocus(true);
                    }
                }}
                onBlur={(event) => {
                    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
                        setIsTabFocus(false);
                    }
                }}
            >
                {isTabFocus ? (
                    <StyledWrapper>
                        <StyledAccesibilityUspDisplay>
                            {uspItems.map((usp) => (
                                <StyledLiWrapper key={weakKey(usp)}>
                                    <UspItem
                                        uspItem={usp}
                                        shade="paragraphInverted"
                                        variant="bodySm"
                                    />
                                </StyledLiWrapper>
                            ))}
                        </StyledAccesibilityUspDisplay>
                    </StyledWrapper>
                ) : (
                    uspItems.map((usp, index) => (
                        <m.div
                            key={weakKey(usp)}
                            variants={animationType}
                            animate={shownItem === index ? 'animate' : 'exit'}
                            style={{ zIndex: shownItem === index ? 1 : 0 }}
                            initial="initial"
                        >
                            <UspItem uspItem={usp} shade="paragraphInverted" variant="bodySm" />
                        </m.div>
                    ))
                )}
            </StyledUspContainer>
        );
    }
);
