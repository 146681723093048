import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { Button, Link, Text } from '~/shared/components';
import { getClampFullRange } from '~/theme/utils/getClampValue';
import { breakpoints } from '~/theme';
import { shouldNotForwardProps } from '~/shared/utils/styled';

export const StyledMarketContent = styled.div(({ theme: { spacesRaw } }) => ({
    display: 'flex',
    padding: `${getClampFullRange(spacesRaw[3], spacesRaw[6])}
          ${getClampFullRange(spacesRaw[5], spacesRaw[8])}`,
}));

export const StyledMarketList = styled.ul(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[3],
}));

export const StyledMarketLink = styled(Link, {
    shouldForwardProp: shouldNotForwardProps(['active']),
})<{ active?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spaces[8],
        textDecoration: 'none',
        borderBottom: theme.borders.borderTransparent,
        paddingBottom: theme.spaces[1],
        fontSize: theme.fontSizes.xs,
        color: theme.colors.dark70,
        padding: theme.spaces[1],
        [':hover']: {
            textDecoration: 'none',
            backgroundColor: theme.colors.light30,
        },
        ['svg']: {
            fontSize: theme.fontSizes['2xl'],
        },
    }),
    ifProp('active', ({ theme }) => ({
        borderBottom: theme.borders.border,
    }))
);

export const StyledMarketText = styled(Text)(({ theme }) => ({
    fontWeight: theme.fontWeights.bold,
    marginBottom: theme.spaces[1],
}));

export const StyledMarketButton = styled(Button)<{ shade?: 'light' | 'dark' }>(
    ({ theme }) => ({
        fontSize: theme.fontSizesFluid.sm,
        textTransform: 'uppercase',
        fontWeight: theme.fontWeights.medium,
        gap: theme.spaces[3],
        marginRight: theme.spaces[3],
        marginBottom: theme.spaces[4],
    }),

    switchProp('shade', {
        dark: ({ theme }) => ({
            border: `1px solid ${theme.colors.light}`,
            color: theme.colors.light,
            backgroundColor: theme.colors.dark,
            [':hover']: {
                backgroundColor: theme.colors.dark70,
            },
        }),
    })
);

export const StyledMarketBarWrapper = styled.div(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.dark,
    zIndex: theme.zIndices.max + 1,
    padding: ` ${theme.spaces[3]} ${theme.spaces[2]}`,
}));

export const StyledMarketBarContent = styled.div(({ theme }) => ({
    textAlign: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.colors.dark,
    color: theme.colors.light,
    fontSize: theme.fontSizes.sm,
    gap: theme.spaces[3],
    zIndex: theme.zIndices.max,
    padding: `0 ${theme.spaces[5]}`,
}));

export const StyledCloseButton = styled.button(({ theme }) => ({
    position: 'absolute',
    right: theme.spaces[2],
    bottom: theme.spaces[3],
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    background: 'none',
    alignSelf: 'flex-end',
    color: theme.colors.light,
    fontSize: theme.fontSizes['3xl'],
    [breakpoints.md]: {
        bottom: 0,
        top: 0,
        margin: 'auto',
    },
}));
