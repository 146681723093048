import cookie from 'cookie';
import jsCookie from 'js-cookie';
import { NextRequest, NextResponse } from 'next/server';

export function leverageNextLocaleCookieServer(response: NextResponse, locale?: string | null) {
    if (!locale) {
        return;
    }

    response.headers.set('Set-Cookie', cookie.serialize('NEXT_LOCALE', locale));
}

export function getNextLocaleCookieServer(
    request: NextRequest
): Record<string, string> | undefined {
    return request.cookies.get('NEXT_LOCALE');
}

export function leverageNextLocaleCookieClient(locale?: string | null) {
    if (!locale) {
        return;
    }

    jsCookie.set('NEXT_LOCALE', locale);
}

export function getNextLocaleCookieClient() {
    jsCookie.get('NEXT_LOCALE');
}
