import styled from '@emotion/styled';
import { Text } from '$shared/components/Text';
import { Button, ButtonProps } from '$shared/components/Button';
import { ifProp } from 'styled-tools';
import { breakpoints } from '$theme';
import { scrollbarThin } from '~/shared/utils/styled';

export const StyledSearchSuggestions = styled.div(({ theme }) => ({
    margin: `${theme.spaces[4]} 0 0 0`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces['10px'],
    alignItems: 'flex-start',
}));

export const StyledSearchSuggestionsTitle = styled(Text)<{ gutter: boolean }>(
    ({ theme, gutter }) => ({
        textEdge: 'cap',
        leadingTrim: 'both',
        textTransform: 'uppercase',
        marginLeft: gutter ? theme.gridConfig.gutter : 0,
    })
);

export const StyledSearchSuggestionsItems = styled.div<{
    gutter: boolean;
    shade: ButtonProps['shade'];
}>(
    ({ theme, gutter }) => ({
        display: 'flex',
        gap: theme.spaces[2],
        width: '-webkit-fill-available',
        overflowX: 'auto',
        flexWrap: 'nowrap',
        paddingLeft: gutter ? theme.gridConfig.gutter : 0,
        paddingBottom: theme.spaces[2],
        ...scrollbarThin,
        [breakpoints.sm]: {
            overflowX: 'visible',
            flexWrap: 'wrap',
        },
    }),
    ifProp({ shade: 'light' }, ({ theme }) => ({
        '&::-webkit-scrollbar-track': {
            background: theme.colors.light50,
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.light,
            '&:active, &:hover': {
                backgroundColor: theme.colors.light30,
            },
        },
    }))
);

export const StyledSearchSuggestionsContainer = styled.div<{ shadowColor?: string }>(
    () => ({
        position: 'relative',
        width: '100%',
    }),
    ifProp('shadowColor', ({ shadowColor, theme }) => ({
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: theme.spaces[4],
            zIndex: 2,
            pointerEvents: 'none',
            background: `linear-gradient(to left, ${shadowColor} 0%, transparent 100%)`,
        },
    }))
);

export const StyledSearchSuggestionsItem = styled(Button)<ButtonProps>(({ theme }) => ({
    overflow: 'hidden',
    ':last-of-type': {
        marginRight: theme.spaces[3],
    },
}));
