import { RawHtml } from '$shared/components';
import { GridItem, GridItemProps } from '../GridItem';
import { StyledText } from './styled';
import { FooterLogo } from '~/shared/components/Logo/FooterLogo';

type RichTextAreaProps = GridItemProps & {
    text?: string;
};

export const RichTextArea = ({ text }: RichTextAreaProps) => {
    return (
        <GridItem area="richtext">
            <StyledText>
                <FooterLogo size="large" />
                {text && <RawHtml html={text} />}
            </StyledText>
        </GridItem>
    );
};
